<script setup>
  import Tooltip from '@/Components/Tooltip.vue';

  defineProps({
    header: {
      type: String,
      required: true,
    },
    actionText: {
      type: String,
      default: null,
    },
    toolTipText: {
      type: String,
      default: null,
    },
  });

  const emit = defineEmits(['clicked']);
</script>

<template>
  <div class="w-full flex justify-between">
    <h2 class="font-semibold text-lg text-slate-600">
      {{ header }}
      <Tooltip
        v-if="toolTipText"
        :content="toolTipText"
        class="inline-block m-auto align-middle w-5"
      />
    </h2>
    <span v-if="actionText" class="font-bold cursor-pointer text-blue" @click="emit('clicked')">
      {{ actionText }}
    </span>
    <slot />
  </div>
</template>

<style></style>
