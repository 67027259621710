<template lang="">
    <div @mouseover="hovered = true" @mouseleave="hovered = false" :class="class">
        <InformationCircleIcon data-tooltip-target="tooltip-default" class="h-5 ml-2 cursor-help text-blue hover:text-blue "> 
        </InformationCircleIcon>
        <div v-show="hovered" :class="`inline-block absolute z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300 tooltip whitespace-pre ${textAlignClass}`">
            {{content}}
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div> 
    </div>
</template>
<script>
import {InformationCircleIcon} from '@heroicons/vue/solid'
export default {
    props: {
        content: {
            type: String,
            required: true
        },
        class: {
            type: String,
            default: ''
        },
        align: {
            type: String,
            default: 'center',
            validator: value => ['left', 'center', 'right'].includes(value)
        }
    },
    components: {InformationCircleIcon},
    data() {
        return {
            hovered: false
        }
    },
    computed: {
        textAlignClass() {
            return {
                'left': 'text-left',
                'center': 'text-center',
                'right': 'text-right'
            }[this.align] || 'text-center';
        }
    }
}   
</script>